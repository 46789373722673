.card {
    padding: 1.5rem;
}

.card > div > h2{
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.2;
}

.floatRight {
    float: right;
}

.mr2 {
    margin-right: 1rem;
}

.iconButton {
    margin-right: 0.5rem;
}
